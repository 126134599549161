.gameMakerContent {
  padding-top: 71px;
}

.general {
  margin-left: 40px;
  margin-top: 0px;
}

.title {
  font-size: xx-large;
  font-weight: bold;
  margin-top: 40px;
  margin-bottom: 20px;
}

.introText {
  font-size: x-large;
  font-weight: bold;
}

.secondaryText {
  margin-top: 7px;
  font-size: large;
}

.gridContainer {
  display: flex;
  flex-wrap: wrap; /* Allow items to wrap to the next row */
  justify-content: flex-start; /* Align items to the left (you can change this as needed) */
  align-items: flex-start; /* Align items to the top (you can change this as needed) */
  gap: 60px; /* Gap between items (adjust as desired) */
  margin-top: 80px;
}
