.homeHeader {
  font-family: 'Lato', sans-serif;
  background: linear-gradient(rgb(247, 247, 247), rgba(135, 60, 255, 0) 0%),
    linear-gradient(316deg, rgba(255, 255, 255, 0.9) 30%, rgb(20, 134, 223) 00%);
  display: flex;
  padding: 50px; /* Add padding as needed */
  /* Adjust this value according to your needs */
  justify-content: space-between; /* Put space between the child elements */
  align-items: flex-start;
}

.introText {
  flex: 1; /* This will allow both sections to equally take up space, you can adjust this */
  padding-right: 20px;
  font-size: large;
  color: white;
}

.introImage {
  flex: 1;
  padding-left: 20px;
}

img {
  max-width: 100%; /* Ensuring the image doesn't overflow its container */
}
