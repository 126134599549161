/*Navbar.module.css*/

.navbar {
  display: flex;
  padding: 0.7rem;
  top: 0;
  justify-content: space-between;
  background-color: var(--primary-color);
  position: fixed;
  width: 100%;
}
.navbar a {
  text-decoration: none;
  color: white;
  font-family: 'Lato', sans-serif;
  margin-right: 15px; /* assuming you added this from the previous step */
  padding: 5px 10px; /* added some padding for a better hover effect */
  border-radius: 4px; /* optional, to round the edges a bit */
  transition: background-color 0.3s; /* optional, smoothens the background color change */
}
.navbar a:hover {
  color: white; /* keep the text color unchanged */
  background-color: rgba(
    219,
    219,
    219,
    0.753
  ); /* background color change on hover */
}
.navbar a.selected {
  text-decoration: underline;
}

.leftContent {
  display: flex;
  align-items: center;
}

.rightContent {
  display: flex;
  gap: 10px;
  align-items: center;
  padding-right: 3%;
}
.logo {
  width: 70px;
  height: 50px;
}
.logoText {
  font-weight: bold;
  font-size: 1.5em;
  color: azure;
}
.dropBtn {
  background-color: #3498db; /* The color of the button */
  color: white;
  width: 50px; /* The size of the button */
  height: 50px;
  border-radius: 50%; /* Makes it round */
  border: none;
  font-weight: bold;
  font-size: 1.2em;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.dropBtn:focus {
  outline: none;
}
