.general {
  margin-left: 40px;
  margin-top: 0px;
}
.mainText {
  font-size: x-large;
  font-weight: bold;
  margin-top: 40px;
  margin-bottom: 20px;
}
.parentContainer {
  display: flex; /* Set this as a flex container */
  flex-wrap: wrap; /* Allow children to wrap to the next line */
  gap: 40px; /* Optional: Add some gap between quizzes */
}
.gameButton {
  display: flex; /* Set this as a flex container */
  flex-direction: column; /* Stack children vertically */
  justify-content: center; /* Center children horizontally */
  align-items: center; /* Center children vertically */

  width: 240px; /* Adjust the button width as needed */
  height: 300px; /* Adjust the button height as needed */
  border: none;
  background: none;
  padding: 0;
  box-shadow:
    0 4px 8px 0 rgba(0, 0, 0, 0.2),
    0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.imageWrapper {
  min-width: 30%; /* Adjust the button width as needed */
  min-height: 30%;
  max-width: 80%; /* Adjust the button width as needed */
  max-height: 80%;
  padding: 10px;
  background-color: #ffffff; /* Background color for the image area (customize as needed) */
  text-align: center;
}

.titleGame {
  /* Add any styles for the title here */
  font-size: large;
  text-align: center;
}

.buttonContainer {
  display: flex;
  justify-content: space-between; /* This will separate the play button and the right buttons */
  align-items: center;
  padding: 10px 0;
}

.circleButton {
  width: 40px; /* Adjust size as needed */
  height: 40px;
  border-radius: 50%; /* Makes the button circular */
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  background-color: #333; /* Adjust color as needed */
  color: white;
  cursor: pointer;
  transition: background-color 0.3s;
}

.circleButton.playButton {
  margin-right: 40px; /* Adjust as needed */
  background-color: #0c806c;
}

.circleButton.editButton {
  background-color: #1c368d;
}

.circleButton.deleteButton {
  background-color: #cf1818;
}
.circleButton:hover {
  background-color: #555; /* Adjust hover color as needed */
}

.rightButtons {
  display: flex;
  gap: 10px; /* Space between edit and delete buttons */
}
