/* Sidebar container styles */
.sidebar {
  background-color: var(--quaternary-color);
  width: 200px;
  height: 100%;
  position: fixed;
  left: 0;
  display: flex;
  flex-direction: column;
  margin-top: 71px;
}

/* Sidebar link styles */
.sidebar a {
  text-decoration: none;
  color: white;
  padding: 15px;
  transition: background-color 0.3s;
}

/* Hover effect */
.sidebar a:hover {
  background-color: var(--tertiary-color);
}

/* Selected effect */
.sidebar .selected {
  background-color: var(--secondary-color);
}

.divider {
  height: 1px;
  background-color: rgba(0, 0, 0, 0.1);
  margin: 5px 0; /* adjust as per your requirement */
}
