.dropdown {
  position: absolute;
  margin-right: 3%;
  display: flex;
  right: 0;
  width: 250px; /* adjust as needed */
  background-color: white;
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
  flex-direction: column;
  color: grey;
  z-index: 1; /* Ensures dropdown remains on top */
}

.dropdown a,
.dropdown button {
  text-decoration: none;
  color: black; /* black text for links/buttons */
  padding: 8px 16px; /* spacing */
  display: block;
  width: 100%; /* full width of the dropdown */
  text-align: left; /* left align text */
  border: none; /* remove default borders for buttons */
  background-color: transparent; /* transparent background for buttons */
  cursor: pointer; /* hand cursor for buttons */
  transition: background-color 0.3s; /* smoothens the background color change */
  margin-top: 1px; /* Add space at the top of each item */
  margin-bottom: 1px; /* Add space at the bottom of each item */
  border-radius: 0px;
  box-sizing: border-box;
}

.dropdown button {
  display: flex;
  align-items: center;
  gap: 8px; /* adjust this for space between icon and text */
}

.dropdown a:hover,
.dropdown button:hover {
  background-color: rgba(
    219,
    219,
    219,
    0.753
  ); /* background color change on hover */
}
.divider {
  height: 1px;
  background-color: rgba(0, 0, 0, 0.1);
  margin: 5px 0; /* adjust as per your requirement */
}
.userDetails {
  display: flex;
  align-items: center;
  padding: 10px; /* adjust as needed */
}

.userCircle {
  width: 50px; /* adjust as needed */
  height: 50px; /* adjust as needed */
  background-color: var(--primary-color);
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%; /* makes it a circle */
  font-weight: bold; /* makes the text "User" bold */
}

.userInfo {
  margin-left: 10px; /* space between circle and user info */
}

.userName {
  color: black;
  font-weight: bold; /* makes the user name bold */
  font-size: 18px; /* adjust as needed */
}

.userEmail {
  font-size: 14px; /* adjust as needed */
  color: grey;
}
