.generalContainer {
  padding-left: 200px;
  display: flex;
}
.generalPanelSetting {
  display: flex;
  flex-direction: column;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.1);
  padding: 20px;
  margin-top: 71px;
  align-items: center;
}

.leftPanel {
  flex: 0.5; /* adjust as needed */
  max-width: 250px;
  min-width: 150px; /* or whatever maximum width you prefer */
  /* other styles */
}

.middlePanel {
  flex: 1; /* adjust as needed */
  /* other styles */
}

.rightPanel {
  display: flex;
  flex-direction: column;
  flex: 0.5; /* adjust as needed */
  max-width: 250px;
  min-width: 150px; /* or whatever maximum width you prefer */
  /* other styles */
}
.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.formBox {
  padding: 80px;
  background-color: aliceblue;
  text-align: center;
}

.mainText {
  font-size: xx-large;
  font-weight: bold;
  text-align: center;
}

.secondaryText {
  text-align: center;
}

.inputTitle[type='text'] {
  width: 100%;
  padding: 12px 20px;
  margin: 8px 0;
  display: inline-block;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}

.continueBtn {
  /* Your existing button styles here */
  background-color: #0dbe42; /* Background color of the button */
  color: #fff; /* Text color */
  border: none; /* Remove the border */
  padding: 10px 20px; /* Adjust padding to your liking */
  cursor: pointer; /* Add a pointer cursor on hover */
  text-align: center; /* Center-align the text */
  text-decoration: none; /* Remove underlines or decoration */
  display: inline-block; /* Display as an inline block element */
  font-size: 16px; /* Adjust font size to your liking */
  border-radius: 4px; /* Add some border-radius for rounded corners */
  width: 45%;
  margin-bottom: 10px;
  transition: background-color 0.3s ease; /* Smooth transition for background color */
}
.continueBtn:hover {
  background-color: #57d877; /* Slightly lighter green */
}

.continueBtn:disabled {
  background-color: #cccccc; /* Gray background */
  color: #666666; /* Dark gray text */
  cursor: not-allowed; /* Show not-allowed cursor */
}
.cancelBtn {
  background-color: #e41c1c; /* Background color of the button */
  color: #fff; /* Text color */
  border: none; /* Remove the border */
  padding: 10px 20px; /* Adjust padding to your liking */
  cursor: pointer; /* Add a pointer cursor on hover */
  text-align: center; /* Center-align the text */
  text-decoration: none; /* Remove underlines or decoration */
  display: inline-block; /* Display as an inline block element */
  font-size: 16px; /* Adjust font size to your liking */
  border-radius: 4px; /* Add some border-radius for rounded corners */
  width: 45%;
}

.cancelBtn:hover {
  background-color: #e03a3a; /* Slightly lighter green */
}

.fileInputContainer {
  display: block;
  justify-content: center;
  align-items: center;
  margin: 20px;
}

.customFileInput {
  display: none;
}

.customFileLabel {
  padding: 10px 30px;
  background-color: #f1bd2c; /* Green */
  color: white;
  cursor: pointer;
  border-radius: 4px;
  width: 45%;
}

.customFileLabel:hover {
  background: #fcd05a;
}

.imagePreview {
  margin-top: 20px;
  max-width: 100px;
  max-height: 100px;
}

/* Additional CSS */

.quizInfo {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 30%;
}

.titleQuiz {
  font-size: xx-large;
  font-weight: bold;
  white-space: nowrap;
}

.thumbnail {
  max-width: 100px;
  max-height: 100px;
}

.questionContainer {
  height: 500px;
  width: 800px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
  gap: 30px;
  background-size: cover;
  padding: 20px;
  background-repeat: no-repeat; /* Do not repeat the image */
  background-position: center; /* Center the background image */
  /* Add additional styling as needed */
}

.questionInput {
  width: 100%;
  height: 10%;
  margin-top: 20px;
  font-size: large;
  word-wrap: break-word;
}

.myInput[type='file']::file-selector-button {
  margin-right: 20px;
  margin-top: 5%;
  border: none;
  background: #0dbe42;
  padding: 7px 13px;
  border-radius: 4px;
  color: #fff;
  cursor: pointer;
}

.myInput[type='file']::file-selector-button:hover {
  background: #57d877;
}

.pictureUpload {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #c9c9c9b7;
  height: 350px;
  width: 350px;
  gap: 10px;
}

.picturePreview {
  max-width: 300px;
  max-height: 100px;
}

.optionContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around; /* Horizontally centering the elements */
  align-items: center; /* Vertically centering the elements */
  gap: 20px;
  margin-top: auto;
  margin-bottom: 20px;
}

.optionBox {
  width: calc(
    50% - 10px
  ); /* Adjusted width to allow space for 2 options per row */
  height: 50px; /* Same height as .questionInput */
  display: flex;
  align-items: center;
  justify-content: center; /* Center the content inside each option */
}

.option {
  width: 90%;
  height: 90%;
  font-size: large;
}

.radioButton[type='radio'] {
  border: 3px ridge currentcolor;
  border-radius: 60px;
  box-sizing: content-box;
  color: lightblue;
  height: 60px;
  padding: 2px 2px 2px 2px;
  transition-duration: 280ms;
  transition-property: border-color, color;
  transition-timing-function: ease;
  width: 20px;
}
.radioButton[type='radio']:checked {
  color: lightgreen;
}

.addButton {
  background-color: green;
  color: white;
  padding: 10px 20px;
  cursor: pointer;
  display: block;
  margin: 20px auto 0;
}

.addButton {
  /* Your existing button styles here */
  background-color: #0dbe42; /* Background color of the button */
  color: #fff; /* Text color */
  border: none; /* Remove the border */
  padding: 10px 20px; /* Adjust padding to your liking */
  cursor: pointer; /* Add a pointer cursor on hover */
  text-align: center; /* Center-align the text */
  text-decoration: none; /* Remove underlines or decoration */
  font-size: 16px; /* Adjust font size to your liking */
  border-radius: 4px; /* Add some border-radius for rounded corners */
  width: 15%;
  margin-bottom: 10px;
  transition: background-color 0.3s ease; /* Smooth transition for background color */
}
.addButton:hover {
  background-color: #57d877; /* Slightly lighter green */
}

/* LEFT PANEL */

.questionBoxLeftPanel {
  border: 1px solid #6161616e;
  padding: 30px;
  margin-bottom: 10px;
  text-align: center;
  color: #666666;
  background-color: rgb(204, 230, 255);
}

.leftPanelTitle {
  font-size: large;
  font-weight: bold;
  color: #666666;
  padding-bottom: 30px;
}

.saveBtn {
  /* Your existing button styles here */
  background-color: #0dbe42; /* Background color of the button */
  color: #fff; /* Text color */
  border: none; /* Remove the border */
  padding: 10px 20px; /* Adjust padding to your liking */
  cursor: pointer; /* Add a pointer cursor on hover */
  text-align: center; /* Center-align the text */
  text-decoration: none; /* Remove underlines or decoration */
  display: inline-block; /* Display as an inline block element */
  font-size: 16px; /* Adjust font size to your liking */
  border-radius: 4px; /* Add some border-radius for rounded corners */
  width: 65%;
  margin-top: auto;
  transition: background-color 0.3s ease; /* Smooth transition for background color */
}
.saveBtn:hover {
  background-color: #57d877; /* Slightly lighter green */
}

.saveBtn:disabled {
  background-color: #cccccc; /* Gray background */
  color: #666666; /* Dark gray text */
  cursor: not-allowed; /* Show not-allowed cursor */
}
