/* GameSelectTypeBtn.module.css */

/* Container for the entire button */
.gameButton {
  position: relative;
  width: 240px; /* Adjust the button width as needed */
  height: 300px; /* Adjust the button height as needed */
  border: none;
  background: none;
  padding: 0;
  cursor: pointer;
  overflow: hidden;
  box-shadow:
    0 4px 8px 0 rgba(0, 0, 0, 0.2),
    0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

/* Styling for the image at the top */
.imageWrapper {
  width: 100%;
  height: 100%; /* Occupy the entire button height */
  background-color: #f2f2f2; /* Background color for the image area (customize as needed) */
  text-align: center;
  position: relative;
}

.image {
  width: 100%;
  height: 70%; /* Adjust the maximum height for the image */
  object-fit: cover; /* Ensure the image covers the entire container */
}

/* Styling for the green circle with a plus icon in the middle */
.plusCircle {
  position: absolute;
  top: 70%; /* Center the green circle vertically */
  left: 50%;
  transform: translate(
    -50%,
    -50%
  ); /* Center the green circle horizontally and vertically */
  background-color: green; /* Adjust the green circle color as needed */
  width: 40px; /* Adjust circle width as needed */
  height: 40px; /* Adjust circle height as needed */
  border-radius: 50%; /* Make it a circle */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1; /* Ensure the green circle appears below the text */
}

.plusIcon {
  font-size: 24px; /* Customize plus icon size */
  color: #fff; /* Icon color (white) */
}

/* Styling for the text title at the bottom */
.title {
  position: absolute;
  top: 80%;
  width: 100%;
  font-size: 19px; /* Customize text size */
  text-align: center;
  font-weight: bold;
}
