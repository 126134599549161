* {
  box-sizing: border-box; /* Include padding and border in element's total width and height */
}

.layout {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 50px;
  gap: 10px;
  width: 100%;
}

input,
button {
  width: 60%;
  padding: 10px 20px;
  font-size: 16px;
  border-radius: 5px;
}

button {
  background-color: #2d9bf0;
  color: white;
  border: none;
  cursor: pointer;
  text-align: center;
  transition: background-color 0.3s ease;
}

button:hover {
  background-color: #52aff7;
}

.button.selected {
  background-color: #0c4877;
}

button:disabled {
  background-color: rgb(180, 180, 180);
}

.spinner {
  border: 8px solid #f3f3f3;
  border-top: 8px solid #2d9bf0;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@media only screen and (max-width: 600px) {
  input,
  button {
    font-size: 14px;
    padding: 12px 24px;
  }
}
